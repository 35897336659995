import axios from "axios"
import { Cookies } from "react-cookie"

export class DecryptToken {
  constructor() {
    this.google = {}, this.cookies = new Cookies()
    this.encrypted = this.cookies.get(process.env.REACT_APP_COOKIE_NAME)
  }

  async encrypt(data) {
    data.req = "encrypt"
    return await axios.post(`${process.env.REACT_APP_DATA_URL}ui/encryption`,
      data, { headers: { "Content-Type": "application/json" } }
    ).then(res => { return res }).catch(err => console.log(err))
  }

  async decrypt() {
    if (this.encrypted) {
      return await axios.post(`${process.env.REACT_APP_DATA_URL}ui/encryption`,
        { encrypted: this.encrypted, req: "decrypt" },
        { headers: { "Content-Type": "application/json" } }
      ).then(res => { return res }).catch(err => {
        console.log(err)
        this.cookies.remove(process.env.REACT_APP_COOKIE_NAME)
        const param = "?source=server-update"
        window.location.assign(process.env.REACT_APP_BASE_URL + param)
      })
    }
  }

  async token() {
    if (this.encrypted) {
      this.google.token = await this.decrypt().then(res => {
        return res.data.token
      }).catch(err => console.log(err))
      return await axios.post(`${process.env.REACT_APP_DATA_URL}ui/local-token`,
        { google_token: this.google.token },
        { headers: { "Content-Type": "application/json" } }
      ).then(res => { return res.data.access_token }).catch(err => console.log(err))
    }
  }
}