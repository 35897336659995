import axios from "axios"
import { DecryptToken } from "./DecryptToken"

export class Auth {
  constructor() {
    this.promise = new Promise((resolve, reject) => {
      this.dt = new DecryptToken()
      Promise.all([
        this.dt.token().catch(err => reject(err)),
        this.dt.decrypt().catch(err => reject(err))
      ]).then(([token, google]) => {
        this.token = token
        this.google = google
        resolve()
      }).catch(err => reject(err))
    })
  }

  async details(req = "details-company") {
    await this.promise
    if (req === "details-company") {
      if (
        this.google.data.customerCode === "RVT-RVT" &&
        typeof this.google.data.accessedCode !== "undefined"
      ) {
        return await axios.get(`${process.env.REACT_APP_DATA_URL}ui/get`, {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${this.token}`
          }, params: { code: this.google.data.accessedCode, req: req }
        }).then(res => { return res.data[0] }).catch(err => console.log(err))
      } else {
        return await axios.get(`${process.env.REACT_APP_DATA_URL}ui/get`, {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${this.token}`
          }, params: { code: this.google.data.customerCode, req: req }
        }).then(res => { return res.data[0] }).catch(err => console.log(err))
      }
    } else {
      return await axios.get(`${process.env.REACT_APP_DATA_URL}ui/get`, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${this.token}`
        }, params: { token: this.google.data.token, req: req }
      }).then(res => { return res.data[0] }).catch(err => console.log(err))
    }
  }

  async verify(email) {
    return await axios.post(`${process.env.REACT_APP_DATA_URL}ui/google-token`,
      { email: email }, { headers: { "Content-Type": "application/json" } }
    ).then(res => { return res.data }).catch(err => console.log(err))
  }

  async googleSSO() {
    return await axios.get(`${process.env.REACT_APP_SSO_URL}auth/login/success`, {
      withCredentials: true, headers: { "Access-Control-Allow-Credentials": true }
    }).then(res => { return res }).catch(err => console.log(err))
  }
}